import React from 'react';
import logo from "../pic/logo_kalfa.png";
import phone from '../pic/phone.png'
import {IoCall} from "react-icons/io5";
import {PiPhoneCallLight} from "react-icons/pi";
import Navbar from "./Navbar";
import {Link} from 'react-router-dom'


function Header() {

  return (
    <div className="header">
      <div className="callHeater">
        <div className="callTextHeater">
          <div>:התקשרו אלינו</div>
          <div>077-5334997</div>
        </div>
        <PiPhoneCallLight className='callImg'/>
      </div>
      <div className="headerCountener">
        <Link to="" className='headerText'>סיפורי הצלחה שלנו</Link>
        <Link to="" className='headerText'>הצוות שלנו</Link>
        <Link to="" className='headerText'>תחומי עיסוק</Link>
      </div>
      <Navbar/>
      <Link to="">     <img src={logo} className="tkHteater" alt="logo"/> </Link>

    </div>
  );
}

export default Header;