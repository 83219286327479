import React from 'react';
import {FaWhatsapp} from "react-icons/fa";import { IoCall } from "react-icons/io5";


function Footer(props) {
  return (<div>
      <div className="footer">

        <div className="infoFooter" href="https://wa.me/0526655323?text=אני%20מעניין/ת%20בפגישת%20ייעוץ">
          <div className='infoH1Footer'>זמינים גם בווצאפ</div>
          <div>052-6655323</div>
          <FaWhatsapp/>
        </div>
        <div className="infoFooter">
          <div className='infoH1Footer'>מיקום</div>
          <div>עוזיאל 1, רמת גן</div>
        </div>

        <div className="infoFooter">
          <div className='infoH1Footer'>אימייל</div>
          <div>oferkalfa2002@yahoo.com</div>
        </div>


        <div className="infoFooter">
          <div className='infoH1Footer'>טלפון</div>
          <div>077-5334997</div>
        </div>


        <div className="infoFooter">
          <div className='textToLeft infoH1Footer'>פנו אלינו לקבלת</div>
          <div className=' textToRight infoH1Footer'>: ייעוץ אישי ללא התחייבות</div>
        </div>
      </div>
      <div className="footerSmall">
        <div className="footerSmallButtonMessage">
          <FaWhatsapp/>
            <p>שלחו הודעה  </p>
        </div>
             <div className="footerSmallButtonCall">
               <IoCall />
               <p> התקשרו עכשיו</p>
                </div>

      </div>
    </div>
  );
}

export default Footer;