import React from 'react';
import backG from '../pic/4.jpg'
import backW from '../pic/word.jpeg'
import ofer from '../pic/kalfa.png'

function InfoHome(props) {
  return (
    <div className='infoHome' style={{ backgroundImage: `url(${backW})`,
                                       backgroundRepeat: "no-repeat",
                                         backgroundSize: "cover"}} >

      <div className='person'>
        <img src={ofer} alt="Logo" />
      </div>
      <div className="infoBlock"></div>
    </div>
  );
}

export default InfoHome;