import React from 'react';
import { FaBars,FaTimes } from "react-icons/fa";
import {useRef,useState} from "react";
import {Link} from 'react-router-dom'


function Navbar(props) {
  const [open,setOpen]=useState(false);
  const navRuf=useRef();
  const showNavbar=()=>{
    navRuf.current.classList.toggle("responsive_nav");
    setOpen(!open);
  }

  return (
    <div className="navbarHeader">
      {open?
      <FaTimes className="navbarHeaderIcon " onClick={showNavbar} />:
        <FaBars className="navbarHeaderIcon" onClick={showNavbar} />
      }
      <nav ref={navRuf} className="close" >
        <Link to="">סיפורי הצלחה שלנו</Link>
        <Link to="">הצוות שלנו</Link>
        <Link to="">תחומי עיסוק</Link>
      </nav>
    </div>
  );
}

export default Navbar;