import MainPage from './assets/mainPage/MainPage'



function App() {
  return (
    <div>
      <MainPage/>
    </div>
  );
}

export default App;
