import React from 'react';
import App from "../../App";
import Header from '../components/Header'
import '../mainPage/mainPage.css'
import Footer from "../components/Footer";
import InfoHome from "../components/InfoHome";
import {Route,Routes} from 'react-router-dom'

function MainPage() {
  return(<div>
      <Header/>
    <Routes>
      <Route path="/" element={  <InfoHome/>}/>
      <Route path="/" element={  <InfoHome/>}/>
      <Route path="/" element={  <InfoHome/>}/>
        </Routes>

      <Footer/>
    </div>
  )
}
export default MainPage;